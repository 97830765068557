
import KTLoader from "@/components/Loader.vue";
import {
  asideEnabled,
  contentWidthFluid,
  loaderEnabled,
  loaderLogo,
  subheaderDisplay,
  themeDarkLogo,
  themeLightLogo,
  toolbarDisplay,
} from "@/core/helpers/config";
import { removeModalBackdrop } from "@/core/helpers/dom";
import HtmlClass from "@/core/services/LayoutService";
import KTHeader from "@/layout/header/Header.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { computed, defineComponent, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "Layout",
  components: {
    KTHeader,
    KTLoader,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    onMounted(() => {
      // Simulate the delay page loading
      // setTimeout(() => {
      // Remove page loader after some time
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      // }, 500);
    });

    watch(
      () => route.path,
      () => {
        removeModalBackdrop();
      }
    );

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      themeLightLogo,
      themeDarkLogo,
    };
  },
});
