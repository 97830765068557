import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import i18n from "@/core/plugins/i18n";
import "dayjs/locale/es";
import ElementPlus from "element-plus";
import ElementPlusEs from "element-plus/es/locale/lang/es";
import router from "./router";
import store from "./store";

import Vue3Lottie from "vue3-lottie";
import "vue3-lottie/dist/style.css";

// Yup locale
import { setLocale } from "yup";
import { es } from "yup-locales";
setLocale(es);

//imports for app initialization
import { initVeeValidate } from "@/core/plugins/vee-validate";
import ApiService from "@/core/services/ApiService";
import $ from "jquery";

import "select2";
import "select2/dist/css/select2.min.css";
import { initi18nes } from "./core/plugins/i18n-select2";

import "bootstrap";
import "flatpickr/dist/flatpickr.css";

// Default config select2
$.fn.select2.defaults.set("theme", "bootstrap5");
$.fn.select2.defaults.set("width", "100%");
$.fn.select2.defaults.set("selectionCssClass", ":all:");

const app = createApp(App);

app.use(store);
app.use(router);

app.use(ElementPlus, {
  locale: ElementPlusEs,
});

app.use(Vue3Lottie, { name: "LottieAnimation" });
ApiService.init(app);
ApiService.setupInterceptors(store);
initVeeValidate();
initi18nes();

app.use(i18n);

app.mount("#app");
